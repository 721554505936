.paymentcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    text-align: center;
}

.paymentcontainer img{
    width:11rem;
}

.paymentcontainer h4{
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: 400;
}