.header-container {
    max-width: 100%;
    min-height: 0rem;
    margin: -2.7em 1rem 0rem 1rem;
    z-index: 1000;
  }
  
  .header-wrap {
    background-color: var(--color-pry-100);
    max-width: var(--max-container);
    min-height: 3.125rem;
    margin: var(--center-container);
    display: flex;
    align-items: center;
  }
  
  .header-logo img {
    max-width: 7.3125rem;
    height: auto;
    position: relative;
    z-index: 1000;
  }
  
  .logo {
  }
  
  .mobile-nav {
    display: none;
  }
  
  .mobile-nav button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    z-index: 1000;
  }
  
  .mobile-nav i {
    font-size: 1.4rem;
  }
  
  .mobile-nav i {
    color: black;
  }
  
  .mobile-nav.active i {
    color: var(--color-pry-100);
  }
  
  @media screen and (max-width: 786px) {
    .mobile-nav {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  
  .header-nav {
    flex: 10;
    display: flex;
    align-items: center;
  }
  
  .header-nav > * {
    flex: 1;
  }
  
  @media screen and (max-width: 786px) {
    .header-nav {
      flex-direction: column;
      background-color: hsla(229, 31%, 21%, 0.951);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100vh;
      display: none;
    }
  
    .header-nav.active {
      display: flex;
    }
  }
  
  .nav-items {
    flex: 10;
    display: flex;
    justify-content: flex-end;
  }
  
  @media screen and (max-width: 786px) {
    .nav-items {
      flex: 1;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 7rem;
    }
  }
  
  .nav-item {
    margin-right: 3rem;
    text-transform: uppercase;
  }
  
  
  @media screen and (max-width: 786px) {
    .nav-item {
      margin-right: 0rem;
      text-align: center;
      border-top: 0.1rem solid hsl(0, 0%, 59%);
      padding: 1rem 0rem;
      margin: 0rem 1rem;
      color: var(--color-pry-100);
    }
  }
  
  .nav-cta-desktop button {
    width: 100%;
    margin-right: 1.8rem;
    background-color: #D52D27;
    border: 2px solid #D52D27;
  }
  
  @media screen and (max-width: 786px) {
    .nav-cta-desktop {
      display: none;
    }
  }
  
  .nav-cta-mobile {
    display: none;
  }
  
  .nav-cta-mobile button {
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 786px) {
    .nav-cta-mobile {
      flex: 10;
      margin: 1rem;
      width: 100%;
      display: block;
      padding: 0rem 1rem;
    }
  }
  
  .header-social {
    display: none;
  }
  
  @media screen and (max-width: 786px) {
    .header-social {
      display: flex;
    }
  }
  
  .header-social-item {
    margin: 1rem;
  }
  
  .social {
  }
  