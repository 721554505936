

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }
  
  .header {
    background-color: #f2f2f2;
    position: absolute;
    top: -2rem;
    left: 1rem;
    text-align: center;
  }
  
  .logo {
    width: 7rem;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: none;
  }


  .login-form form{
    width: 350px !important;
    box-shadow: #ccc 0px 0px 2px 0px;
  }


  .login-form img{
    width: 70px;
    margin-bottom: 1rem;
  }

  .login-form h2{
    margin-bottom: 0rem;
  }

  .login-form p{
    margin-bottom: 1rem;
  }
  
 .login-form .image {
    width: 250px;
    margin-bottom: 20px;
  }
  
  .login-form .input {
    padding: 10px;
    margin-bottom: 10px;
    width: 270px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  
  
  