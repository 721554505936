.employeedetails{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    text-align: center;
    justify-content: center;
}

.employeedetails h4{
    margin-bottom:10px ;
}


.chartcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    text-align: center;
}

.timeamountchart{
    width: 90%;
    height: 400px;
    margin-top: 30px;
}