.addcons{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    
}






form {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30rem;
    background-color: #f2f2f2;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s;
  }
  
  form.hidden {
    opacity: 0;
  }
  
  form.visible {
    opacity: 1;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 0 auto;
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  form h2 {
    margin-bottom: 20px;
  }
  
  form input {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  form input:hover{
    border-color: #D52D27;
  }

  form input:focus{
    border-color: #D52D27;
  }

  form input:active{
    border-color: #D52D27;
  }
  
  form input[type='date'] {
    padding: 8px;
  }
  
  form input[type='submit'] {
    background-color: #333;
    color: white;
    cursor: pointer;
  }
  
  form input[type='submit']:hover {
    background-color: #555;
  }
  


  .invoicepanel{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  .buttoncontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 30PX;
  }