.enquiries-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
  }
 
  .card {
    background-color: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 16px;
    margin-bottom: 16px;
    max-width: 350px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }

  .card:hover{
    box-shadow:none
  }
  
  .card-field {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .card-value {
    color: #878c8f;
    font-size: 16px;
    margin-left: 10px;
    position: relative;
  }
  
  .card-label {
    color: #7e8488;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    text-transform: uppercase;
  }
  
  .enqicon {
    color: #848687;
    font-size: 20px;
    margin-right: 10px;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
  }
  
  .enqicon:hover {
    opacity: 1;
  }

  .buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
  }